import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoGaleno from "../../assets/old/img/logos-ase/ico-galeno.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="galeno">
        <Helmet>
            <title>Galeno Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Galeno Seguros: 0800-777-5433."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoGaleno}
                    alt="Galeno Seguros"
                />
                <h4 className="lead post-title text-muted">Galeno Seguros</h4>
                <p className="">Servicio de asistencia: 0800-777-5433.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            A fin de poder asesorarte y brindarte asistencia es conveniente que el llamado al 0800 se realice en forma inmediata.
        </p>
        <h6>¿Quiénes pueden realizar la denuncia?</h6>
        <p>
            La denuncia puede ser realizada por el asegurado o el conductor del vehículo al momento del siniestro.
        </p>
        <h6>Me rompieron un vidrio. Forzaron una cerradura o me robaron una rueda</h6>
        <p>
            Siempre hay que llamar al 0-800-777-5433 para realizar la denuncia y considerando el tipo de cobertura contratada y las pautas de suscripción, desde el mismo 0800 se te va a derivar a un prestador para la reposición de la rueda, cambio del vidrio o arreglo de la cerradura.
        </p>

        <h6>Si otro vehículo me chocó y necesito realizar el reclamo en la otra compañía. ¿Qué necesito?</h6>
        <p>
            Debe realizar la denuncia correspondiente en Galeno Seguros al 0-800-777-5433 donde será asesorado.
        </p>

        <h6>¿Si soy un tercero damnificado por un asegurado de Galeno Seguros? ¿Cómo hago para presentar el reclamo?</h6>
        <p>
            Tener en cuenta que para realizar el reclamo correspondiente es indispensable que previamente el asegurado de Galeno Seguros realice la denuncia. <br/>
            Si desea conocer el estado del trámite podrá comunicarse al 0800-777-5433.
        </p>

        <hr className="pb-4" />



    </SiniestrosLayout>
)
